import { MaterialIcons } from '@expo/vector-icons';
import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Image, StyleSheet, TextInput, View } from 'react-native';

import BoidQrCode from 'components/BoidQrCode';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import AuthContext from 'services/auth/AuthContext';
import BoidCardService from 'services/boid-card/BoidCardService';
import ConnectionService from 'services/connection/ConnectionService';

import COLORS from 'styles/colors';

import DateChanger from './DateChanger';
import Office from './Office';
import RoundedButton from './RoundedButton';
import SlackReachOutButton from './SlackReachOutButton';
import StyledButton from './StyledButton';
import StyledText from './StyledText';

// information that is show in the backside of boidCard

export type BackOfCardProps = {
  boidCard: ExtendedBoidCardModel;
  profileImage: string | undefined;
  showNotes: boolean;
  showTimeOfConnecton: boolean;
  flipCardFunction: Function | undefined;
  showBoidQrCode: boolean;

  //Add QR code aswell
};

function BackOfCard(props: BackOfCardProps): ReactElement {
  const {
    boidCard,
    profileImage,
    showNotes,
    showTimeOfConnecton,
    flipCardFunction,
    showBoidQrCode,
  } = props;

  type connectionData = {
    city: string;
    date: string;
  };

  const { signOut } = useContext(AuthContext);

  const [myBoidCard, setMyBoidCard] = useState<ExtendedBoidCardModel>();
  const [notesText, setNotesText] = useState<string>('');
  const [timeOfConnection, setTimeOfConnection] = useState<connectionData>();
  const [showImage, setShowImage] = useState<boolean>(false);
  const [role, setRole] = useState<string>('Role');
  const [office, setOffice] = useState<string>('City');
  const [slackId, setSlackId] = useState<string | null>(null);
  const [noteSaved, setNoteSaved] = useState<string>('notSaved');

  useEffect(() => {
    BoidCardService.getMyExtendedBoidCard().then(setMyBoidCard);
  }, []);

  useEffect(() => {
    if (boidCard.office) {
      setOffice(boidCard.office);
    }
    if (boidCard.role) {
      setRole(boidCard.role);
    }
    if (profileImage) {
      setShowImage(true);
    } else {
      setShowImage(false);
    }

    if (boidCard && myBoidCard) {
      //Added this check so that you wont fetch info from your own card.

      if (!(boidCard.id === myBoidCard.id)) {
        ConnectionService.getConnection(boidCard.id).then((backOfCardInfo) => {
          if (showNotes) {
            setNotesText(backOfCardInfo.note);
          }
          DateChanger(backOfCardInfo.sharedData.connectedAtTime);
          if (showTimeOfConnecton) {
            let data: connectionData = {
              city: 'City',
              date: DateChanger(backOfCardInfo.sharedData.connectedAtTime),
            };
            setTimeOfConnection(data);
          }
        });
      }
    }

    if (!timeOfConnection) {
      let mockData: connectionData = {
        city: 'City',
        date: 'Month Year',
      };
      setTimeOfConnection(mockData);
    }

    if (boidCard.slackUserId) {
      setSlackId(boidCard.slackUserId);
    }
  }, [boidCard, profileImage, myBoidCard]);

  function postNote(): void {
    if (boidCard && myBoidCard && boidCard.id !== myBoidCard.id) {
      setNoteSaved('saving');
      ConnectionService.addNote(boidCard.id, notesText)
        .then((response) => {
          if (response.status) {
            if (response.status === 200) {
              setNoteSaved('saved');
            }
          }
        })
        .catch((err) => {
          setNoteSaved('error');
        });
    }
  }

  function cityAndDateContainer(text: string | undefined): ReactElement {
    return (
      <View style={styles.CityAndDateContainer}>
        <StyledText>{text}</StyledText>
      </View>
    );
  }
  const saveNotefeedback =
    noteSaved === 'saved' ? (
      <StyledText style={styles.savedFeedBack}>
        Saved <MaterialIcon name="done" size={15} />
      </StyledText>
    ) : noteSaved === 'error' ? (
      <StyledText style={[styles.savedFeedBack, { color: 'red' }]}>
        Something Went wrong!
      </StyledText>
    ) : noteSaved === 'saving' ? (
      <StyledText style={styles.savedFeedBack}>Saving..</StyledText>
    ) : null;
  return (
    <View style={styles.BackOfCard}>
      {flipCardFunction && (
        <RoundedButton
          onPress={() => flipCardFunction()}
          smallButton={true}
          materialIconName="flip-camera-android"
        />
      )}
      {showImage && (
        <Image source={{ uri: profileImage }} style={styles.profileImageBack} />
      )}
      <View style={styles.topContainer}>
        <StyledText style={styles.profileNameBack}>
          {boidCard.firstName} {boidCard.lastName}
        </StyledText>
        <View>
          <StyledText style={styles.tinyText}>{role}</StyledText>
          <View
            style={{
              width: '25%',
              borderBottomColor: COLORS.PRIMARY,
              borderBottomWidth: 2,
              alignSelf: 'center',
              marginBottom: 10,
              marginTop: 5,
            }}
          ></View>
          <View style={styles.officeAndSlackContainer}>
            <View style={styles.officeContainer}>
              <Office office={office} style={styles.officeImage} />
              <StyledText style={styles.tinyText}>{office}</StyledText>
            </View>
            {!showBoidQrCode && slackId && (
              <SlackReachOutButton userId={slackId} />
            )}
          </View>
        </View>
      </View>
      {showBoidQrCode && (
        <View style={styles.qrContainer}>
          <BoidQrCode boidCode={boidCard?.id} />
          <StyledButton
            style={{
              marginTop: '25px',
            }}
            title="Logout"
            fullWidth
            onPress={signOut}
          />
        </View>
      )}

      {showNotes && (
        <View style={styles.notesContainer}>
          <View style={styles.notesTopContainer}>
            <StyledText>My Note</StyledText>
          </View>
          <RoundedButton
            onPress={postNote}
            smallButton={true}
            materialIconName="save"
            style={[
              styles.notesButton,
              {
                width: innerWidth / 10,
                height: innerWidth / 10,
              },
            ]}
          />
          {saveNotefeedback}
          <TextInput
            autoFocus={false}
            multiline={true}
            numberOfLines={4}
            style={[
              styles.notesText,
              {
                paddingBottom: innerHeight / 10,
                height: showImage ? '65%' : '50%',
              },
            ]}
            onChangeText={(newText) => setNotesText(newText)}
            defaultValue={notesText}
            placeholder="Here you can write your notes just for yourself about how your encounter with this boid, things you want to remember etc..."
          ></TextInput>
        </View>
      )}
      {showTimeOfConnecton && (
        <View style={styles.timeOfConnectionContainer}>
          <StyledText style={styles.timeOfConnectionText}>
            You have met in {cityAndDateContainer(timeOfConnection?.city)} in{' '}
            {cityAndDateContainer(timeOfConnection?.date)}
          </StyledText>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  profileImageBack: {
    width: '50%',
    aspectRatio: 1,
    borderRadius: 360,
    borderColor: '#000000',
    borderWi12dth: 3,
    alignSelf: 'center',
    maxHeight: innerHeight - innerHeight / 7,
    maxWidth: innerHeight - innerHeight / 7,
  },
  topContainer: {
    width: '100%',
    alignContent: 'center',
  },
  profileNameBack: {
    fontWeight: '700',
    fontSize: 24,
    color: 'black',
    width: '100%',
    marginBottom: 5,
  },
  tinyText: {
    fontWeight: '300',
    fontSize: 12,
    color: 'black',
    marginBottom: 3,
    text: 'center',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  officeAndSlackContainer: {
    flexDirection: 'row',
    alignSelf: 'stretch',
    justifyContent: 'space-evenly',
  },
  officeContainer: {
    flexDirection: 'row',
  },
  notesContainer: {
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    alignSelf: 'center',
  },
  notesTopContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  notesTitleText: {
    fontWeight: '700',
    color: 'black',
    fontSize: 12,
  },
  savedFeedBack: {
    color: COLORS.PRIMARY,
    zIndex: 100,
    position: 'absolute',
    display: 'flex',
    top: '65%',
    left: '5%',
    fontSize: 15,
  },
  notesButton: {
    zIndex: 100,
    borderRadius: 1000,
    backgroundColor: COLORS.PRIMARY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '60%',
    left: '84%',
  },
  notesText: {
    borderColor: COLORS.PRIMARY,
    borderWidth: 1,
    backgroundColor: 'white',
    borderRadius: 14,
    marginBottom: 20,
    width: '100%',
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 7,
  },
  timeOfConnectionContainer: {
    justifyContent: 'center',
  },
  timeOfConnectionText: {
    textAlign: 'center',
    fontWeight: '400',
    color: COLORS.GRAY,
    fontSize: 12,
  },
  BackOfCard: {
    height: '100%',
    width: '100%',
  },
  CityAndDateContainer: {
    borderColor: COLORS.PRIMARY,
    borderWidth: 1,
    borderRadius: 35,
    paddingLeft: 12,
    paddingRight: 12,
    color: COLORS.PRIMARY,
  },
  qrContainer: {
    height: '80%',
    padding: 32,
    alignItems: 'center',
    marginTop: 16,
    justifyContent: 'center',
  },
  officeImage: {
    width: 18,
    aspectRatio: 1,
    marginRight: 10,
  },
});

export default BackOfCard;
