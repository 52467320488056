import React, { ReactElement } from 'react';
import { ScrollView, StyleSheet, Text, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import SectionContainer from 'components/SectionContainer';

import COLORS from 'styles/colors';

function SettingsScreen(): ReactElement {
  return (
    <SafeAreaView style={styles.page}>
      <ScrollView stickyHeaderIndices={[1]} style={styles.scrollContainer}>
        <View style={styles.mainContentContainer}>
          <SectionContainer
            title="Achievements"
            children={undefined}
          ></SectionContainer>
          <Text>Under construction</Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

export default SettingsScreen;

const styles = StyleSheet.create({
  page: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  scrollContainer: {
    width: '100%',
    backgroundColor: COLORS.BACKGROUND,
  },
  mainContentContainer: {
    backgroundColor: COLORS.BACKGROUND,
    paddingTop: 15,
    paddingHorizontal: 24,
    minHeight: '100%',
  },
});
