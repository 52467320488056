import MaterialIcon from '@expo/vector-icons/MaterialIcons';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Animated, Easing, ScrollView, StyleSheet, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';

import BoidCard from 'components/BoidCard';
import LoadingIndicator from 'components/LoadingIndicator';
import PrimaryGradient from 'components/PrimaryGradient';
import SectionContainer from 'components/SectionContainer';

import { ExtendedBoidCardModel } from 'models/BoidCardModel';

import BoidCardService from 'services/boid-card/BoidCardService';

import COLORS from 'styles/colors';

function ProfileScreen(): ReactElement {
  const [boidCodeVisible, setBoidCodeVisible] = useState<boolean>(false);

  const [boidCard, setBoidCard] = useState<ExtendedBoidCardModel>();
  useEffect(() => {
    BoidCardService.getMyExtendedBoidCard().then(setBoidCard);
  }, []);

  const boidCodeOverlayHeight = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.timing(boidCodeOverlayHeight, {
      toValue: boidCodeVisible ? 1 : 0,
      duration: 500,
      easing: Easing.inOut(Easing.cubic),
      useNativeDriver: true,
    }).start();
  }, [boidCodeVisible, boidCodeOverlayHeight]);

  return (
    <SafeAreaView style={styles.page}>
      <View style={styles.mainContentContainer}>
        <PrimaryGradient />
        <ScrollView contentContainerStyle={styles.scrollContainer}>
          {boidCard && (
            <BoidCard boidCard={boidCard} showQRcode={true} myCard={true} />
          )}
          {!boidCard && <LoadingIndicator />}
        </ScrollView>
      </View>
    </SafeAreaView>
  );
}

export default ProfileScreen;

const styles = StyleSheet.create({
  page: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  scrollContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  mainContentContainer: {
    backgroundColor: COLORS.BACKGROUND,
    paddingHorizontal: 20,
    display: 'flex',
    height: '100% ',
    width: '100%',
  },
});
